import React, { useEffect } from 'react'
import { getReviewerAssignmentList, getRevisedFullPaperFiles, setFullPaperFile } from '../../state'
import { useAppDispatch } from 'src/store/hooks'
import Button from 'src/packages/core/button'
import { useForm } from 'react-hook-form'
import validateFileUpload from 'src/shared/helper/file-upload.helper'
import { API_URL } from 'src/shared/data'
import { setToast } from '@/packages/product/toast/state'
import { TSubmissionItem } from '../../data'

type TSubmissionSummarySection = {
  reviewingSubmission: TSubmissionItem
}
const AdminFullPaperUploadPage = ({ reviewingSubmission }: TSubmissionSummarySection) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (reviewingSubmission?.id) {
      console.log('reviewingSubmission author', reviewingSubmission)
      dispatch(getReviewerAssignmentList({ paperId: reviewingSubmission.id, type: 'matching' }))
      dispatch(getRevisedFullPaperFiles(reviewingSubmission.id))
    }
  }, [reviewingSubmission, dispatch])
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const submitRevisedFullPaper = async (data: any) => {
    console.log('submitFullPaper', data)
    const file = data.fullPaper[0]
    const validation = await validateFileUpload(file)
    if (validation.status == 'success') {
      console.log('send api')
      const response = await dispatch(setFullPaperFile({ paperId: reviewingSubmission.id!, file: file }))
      console.log('response file upload', response)
      if (response.payload === 'File too large') {
        dispatch(setToast({ message: `File size should be less than ${process.env.REACT_APP_FP_FILE_SIZE}MB`, variant: 'error' }))
      } else if (response.meta.requestStatus === 'rejected') {
        dispatch(setToast({ message: response.payload as string, variant: 'error' }))
      } else {
        dispatch(setToast({ message: 'Full paper uploaded successfully', variant: 'success' }))
        dispatch(getRevisedFullPaperFiles(reviewingSubmission.id!))
        reset()
      }
    } else {
      // const response = dispatch(setMessage({ message: validation.message, origin: 'submission-upload', status: 'error' }))
      dispatch(setToast({ message: validation.message ?? '', variant: 'error' }))
    }
  }

  const handleDownloadPaper = async (item: any) => {
    const filePath = item.filePath
    if (!filePath) return
    console.log('download paper', filePath)
    const downloadUrl = `${API_URL}/${filePath}`
    const response = await fetch(downloadUrl)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filePath.split('/').pop() || '' // Set the filename to the last segment of the URL
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const styles = {
    table: 'w-full mt-6 border border-gray-200 rounded-lg border-separate',
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 align-top text-left text-black',
  }

  return (
    <>
      <h2 className="text-2xl font-bold pt-10">Upload Full Paper</h2>
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-6" onSubmit={handleSubmit(submitRevisedFullPaper)}>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullPaper">
            Upload Full Paper
            <span className="text-sm italic font-normal">(only doc, docx file format allowed)</span>
            <br />
            <span className="text-sm italic font-normal">max size {process.env.REACT_APP_FP_FILE_SIZE}MB</span>
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
            type="file"
            placeholder="Upload Revised Full Paper"
            {...register('fullPaper')}
          />
        </div>

        <div className="flex items-center justify-between pt-4">
          <Button type="submit">Upload</Button>
        </div>
      </form>
    </>
  )
}
export default AdminFullPaperUploadPage
