import React, { useEffect, useState } from 'react'
import { getFullPaperFiles, getReviewerAssignmentList, setFullPaperFile } from '../../state'
import moment from 'moment'
import { useAppSelector, useAppDispatch } from 'src/store/hooks'
import Button from 'src/packages/core/button'
import { useForm } from 'react-hook-form'
import validateFileUpload from 'src/shared/helper/file-upload.helper'
import NotificationBar from 'src/packages/product/notification'
import NotificationHook from 'src/shared/service/notification.hook'
import { API_URL, SHARED } from 'src/shared/data'
import { setToast } from '@/packages/product/toast/state'

type AuthorReviewSummaryPageProps = {
  paperType: string
}

const AuthorReviewSummaryPage = ({ paperType }: AuthorReviewSummaryPageProps) => {
  const { reviewingSubmission, reviewerAssignmentList, fullPaperFiles } = useAppSelector((state) => state.submission)
  const dispatch = useAppDispatch()
  const { useNotification } = NotificationHook
  const { notification } = useNotification()
  const [allReviewed, setAllReviewed] = useState(false)

  const isAllReviewed = (reviewingSubmission: any, paperType: string) => {
    let isAllReviewed = true
    reviewingSubmission.review.forEach((review: any) => {
      if (review.paperType.code === paperType) {
        if (!review.score) {
          isAllReviewed = false
        }
      }
    })
    return isAllReviewed
  }

  useEffect(() => {
    if (reviewingSubmission?.id) {
      console.log('reviewingSubmission author', reviewingSubmission)
      dispatch(getReviewerAssignmentList({ paperId: reviewingSubmission.id, type: 'matching' }))
      dispatch(getFullPaperFiles(reviewingSubmission.id))
      setAllReviewed(isAllReviewed(reviewingSubmission, paperType))
    }
  }, [reviewingSubmission, dispatch, paperType])
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const submitFullPaper = async (data: any) => {
    console.log('submitFullPaper', data)
    const file = data.fullPaper[0]
    const validation = await validateFileUpload(file)
    if (validation.status == 'success') {
      console.log('send api')
      const response = await dispatch(setFullPaperFile({ paperId: reviewingSubmission.id!, file: file }))
      console.log('response file upload', response)
      if (response.payload === 'File too large') {
        dispatch(setToast({ message: `File size should be less than ${process.env.REACT_APP_FP_FILE_SIZE}MB`, variant: 'error' }))
      } else if (response.meta.requestStatus === 'rejected') {
        dispatch(setToast({ message: response.payload as string, variant: 'error' }))
      } else {
        dispatch(setToast({ message: 'Full paper uploaded successfully', variant: 'success' }))
      }
    } else {
      // const response = dispatch(setMessage({ message: validation.message, origin: 'submission-upload', status: 'error' }))
      dispatch(setToast({ message: validation.message ?? '', variant: 'error' }))
    }
  }

  const handleDownloadPaper = async (item: any) => {
    const filePath = item.filePath
    if (!filePath) return
    console.log('download paper', filePath)
    const downloadUrl = `${API_URL}/${filePath}`
    const response = await fetch(downloadUrl)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filePath.split('/').pop() || '' // Set the filename to the last segment of the URL
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const assignmentInProgress = () => {
    const abstractReviews = reviewingSubmission.review.filter((review: any) => review.paperType.code === SHARED.PAPER_TYPE.ABS)
    const fullPaperReviews = reviewingSubmission.review.filter((review: any) => review.paperType.code === SHARED.PAPER_TYPE.FP)
    if (paperType === SHARED.PAPER_TYPE.ABS && abstractReviews.length === 0) return true
    if (paperType === SHARED.PAPER_TYPE.FP && fullPaperReviews.length === 0) return true
    return false
  }

  const canUploadFP = () => {
    if (
      paperType === SHARED.PAPER_TYPE.FP &&
      (reviewingSubmission.paperStatus.code === SHARED.PAPER_STATUS.NEW ||
        reviewingSubmission.paperStatus.code === SHARED.PAPER_STATUS.IN_PROGRESS)
    )
      return true
    return false
  }

  const styles = {
    table: 'w-full mt-6 border border-gray-200 rounded-lg border-separate',
    title: 'font-bold text-2xl pt-8 pb-4',
    tableRow: 'border border-gray-100',
    tableData: 'p-4 border-b border-gray-100 align-top text-left text-black',
  }

  return (
    <>
      {fullPaperFiles?.length > 0 && paperType === SHARED.PAPER_TYPE.FP && (
        <>
          <h2 className="text-2xl font-bold pt-10">Uploaded Full Paper</h2>
          <table className={`${styles.table}`}>
            <thead>
              <tr className={`${styles.tableRow}`}>
                <th className={`${styles.tableData}`}>File Name</th>
                <th className={`${styles.tableData}`}>Uploaded Date</th>
              </tr>
            </thead>
            <tbody>
              {fullPaperFiles.map((file, index) => {
                return (
                  <tr key={index} className={`${styles.tableRow}`}>
                    <td className={`${styles.tableData}`}>
                      <a className="cursor-pointer underline text-blue-500" onClick={() => handleDownloadPaper(file)}>
                        {file.fileName}
                      </a>
                    </td>
                    <td className={`${styles.tableData}`}>{moment(file.createdAt).format('DD MMM YYYY, hh:mm A')}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
      {canUploadFP() && (
        <>
          <h2 className="text-2xl font-bold pt-10">Upload Full Paper</h2>
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-6" onSubmit={handleSubmit(submitFullPaper)}>
            {notification?.message && <NotificationBar notification={notification} includeOrigin="submission-upload" />}
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullPaper">
                Upload Full Paper
                <span className="text-sm italic font-normal">(only doc, docx file format allowed)</span>
                <br />
                <span className="text-sm italic font-normal">max size {process.env.REACT_APP_FP_FILE_SIZE}MB</span>
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                type="file"
                placeholder="Upload Full Paper"
                {...register('fullPaper')}
              />
            </div>

            <div className="flex items-center justify-between pt-4">
              <Button type="submit">Upload</Button>
            </div>
          </form>
        </>
      )}
      {allReviewed &&
        reviewerAssignmentList.map((assignment, idx) => {
          return (
            <div key={assignment.id}>
              {assignment.review.map((review, index) => {
                if (review.paperType.code === paperType) {
                  return (
                    <div key={index}>
                      <h2 className="text-2xl font-bold pt-10">Review {idx + 1}</h2>
                      <table className={`${styles.table}`}>
                        <tbody>
                          <tr>
                            <td className={`font-bold w-1/6 ${styles.tableData}`}>Detailed Review</td>
                            <td>
                              <div>
                                <p className="text-black">{review.description}</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                }
                return null
              })}
            </div>
          )
        })}

      {!allReviewed && paperType == SHARED.PAPER_TYPE.ABS && (
        <div className="flex items-center justify-between">
          <p className="text-l font-bold pt-10"> Abstract review in progress...</p>
        </div>
      )}
      {!allReviewed && paperType == SHARED.PAPER_TYPE.FP && assignmentInProgress() && (
        <div className="flex items-center justify-between">
          <p className="text-l font-bold pt-10"> Full Paper review in progress...</p>
        </div>
      )}

      {assignmentInProgress() && (
        <div className="flex items-center justify-between">
          <p className="text-l font-bold pt-10">
            {paperType == SHARED.PAPER_TYPE.FP ? 'Full Paper' : 'Abstract'} being assigned reviewers...
          </p>
        </div>
      )}
    </>
  )
}
export default AuthorReviewSummaryPage
