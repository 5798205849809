import React, { useEffect } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import Button from 'src/packages/core/button'
import Icon from 'src/packages/core/icon'
import Tabs from 'src/packages/product/tabs'
import { SHARED } from 'src/shared/data'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { SubmissionDetailHook } from '../../service'
import SubmissionSummarySection from '../reviewer/reviewer-submission'
import ReviewAuthorsSection from './review-authors'
import AssignmentSection from './assignment-section'
import ReviewSummarySection from './review-summary-section'
import { getFullPaperFiles, getRevisedFullPaperFiles } from '../../state'
import AdminFullPaperUploadPage from './full-paper-upload'

const SubmissionSummaryPage = () => {
  const { useHook } = SubmissionDetailHook
  const { useBack, useFetchData } = useHook()
  const { reviewingSubmission, fullPaperFiles, revisedFullPaperFiles } = useAppSelector((state) => state.submission)
  const loader = useAppSelector((state) => state.loader)
  const dispatch = useAppDispatch()
  useFetchData()
  useEffect(() => {
    if (reviewingSubmission && reviewingSubmission.id && !loader) {
      dispatch(getFullPaperFiles(reviewingSubmission.id) as any) // add "as any" to cast the action to AnyAction
      dispatch(getRevisedFullPaperFiles(reviewingSubmission.id) as any) // add "as any" to cast the action to AnyAction
    }
  }, [reviewingSubmission?.id, loader])

  const tabs = [
    {
      name: 'Submission',
      content: (
        <SubmissionSummarySection
          submission={reviewingSubmission}
          fullPaperList={fullPaperFiles}
          revisedFullPaperList={revisedFullPaperFiles}
        >
          <ReviewAuthorsSection />
        </SubmissionSummarySection>
      ),
    },
    { name: 'Assignment', content: <AssignmentSection /> },
    { name: 'Abstract Review', content: <ReviewSummarySection paperType={SHARED.PAPER_TYPE.ABS} /> },
    { name: 'Full Paper Review', content: <ReviewSummarySection paperType={SHARED.PAPER_TYPE.FP} /> },
    { name: 'Full Paper Upload', content: <AdminFullPaperUploadPage reviewingSubmission={reviewingSubmission} /> },
  ]

  return (
    <div>
      <Button type="button" fill="ghost" expand="default" onClick={useBack} className="mt-0 text-neutral">
        <Icon icon={<ChevronLeftIcon className="w-5 mr-2" />} css="text-neutral" /> Back
      </Button>
      <div className="mt-10">
        <Tabs tabs={tabs} />
      </div>
    </div>
  )
}

export default SubmissionSummaryPage
