import { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate, useMatch } from 'react-router-dom'
import { ROUTE_PATH, TCountryItem } from 'src/shared/data'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { defaultValues } from '../data'
import { setMessage } from 'src/packages/product/notification/state'
import { createSubmission, getSubmissionDetail, setReviewingSubmission, submissionSlice, updateSubmission } from '../state'
import { TConferenceItemUpdate } from 'src/pages/conference/data'
import { TSubThemeItem } from 'src/pages/sub-theme/data'
import { getConferences } from 'src/pages/conference/state'
import moment from 'moment'
import { HTTP_STATUS } from 'src/shared/http/data'
import { setToast } from '@/packages/product/toast/state'
import { startLoading, finishLoading } from '@/packages/product/full-page-loader/state'

const isEmpty = (obj: any) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) return false
  }

  return true
}
const buildDataBody = (data: any) => {
  console.log('build data body', data)
  const keyword = data.keyword.map((item: any) => {
    return {
      id: parseInt(item.value),
      name: item.label,
    }
  })
  const paperAuthor = data.paperAuthor
    .filter((item: any) => item.email)
    .map((item: any, index: number) => {
      item.isFirstAuthor = index === 0
      return item
    })
  const subThemeList = []
  if (data.paperSubTheme.length > 0) {
    for (const subTheme of data.paperSubTheme) {
      if (!subTheme) continue
      subThemeList.push(subTheme)
    }
  } else {
    if (!isEmpty(data.paperSubTheme)) subThemeList.push(data.paperSubTheme)
  }
  const body = {
    title: data.title,
    abstract: data.abstract,
    keyword: keyword,
    paperSubTheme: subThemeList,
    paperAuthor: paperAuthor,
  }

  console.log('after build', body)

  return body
}

const useHook = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [subTheme, setSubTheme] = useState<TSubThemeItem[]>([])
  const [country, setCountry] = useState<TCountryItem[]>([])
  const [conference, setConference] = useState<TConferenceItemUpdate>()
  const params = useParams()
  const matchPath = useMatch(ROUTE_PATH.SUBMISSION_ADD)
  const sharedState = useAppSelector((state) => state.shared)

  const useFetchData = () => {
    useEffect(() => {
      let submission: any
      let subThemeList: any
      let conference: any

      const getConferenceDetailAsync = async () => {
        if (!id) return dispatch(setMessage('No id found'))
        conference = await dispatch(getConferences({ year: new Date().getFullYear() }))
        console.log('conference', conference)
        if (!conference.payload) dispatch(setMessage('No records found'))
        const response = { ...conference.payload.data[0] }
        setConference(response)
      }

      const getCountryListAsync = async () => {
        // get country list's data from sharedState
        const {
          countryList: { data },
        } = sharedState

        setCountry(data)
      }

      const getSubmissionDetailAsync = async () => {
        if (!id) return dispatch(setToast({ message: 'No id found', variant: 'error' }))
        dispatch(startLoading())
        submission = await dispatch(getSubmissionDetail(+id))
        const response = { ...submission?.payload }
        if (response.paperSubTheme?.length > 0) {
          response.paperSubTheme = response.paperSubTheme.map((item: any) => {
            return item.subTheme
          })
        }

        if (response.paperAuthor?.length > 0) {
          response.paperAuthor = response.paperAuthor.map((item: any) => {
            return {
              firstName: item.firstName,
              lastName: item.lastName,
              email: item.email,
              affiliation: item.affiliation,
              country: item.country,
              isCorresponding: item.isCorresponding,
              isPresenter: item.isPresenter,
              isMainAuthor: item.isMainAuthor,
            }
          })
        }

        if (response.paperAuthor?.country) {
          response.paperAuthor.country = {
            value: response.paperAuthor.country,
            label: response.paperAuthor.country,
          }
        }

        if (response.updatedAt) {
          response.updatedAt = moment(response.updatedAt).format('MMM DD, YYYY hh:mm A z')
        }
        if (response.submitted_date) {
          response.submitted_date = moment(response.submitted_date).format('MMM DD, YYYY hh:mm A z')
        }
        if (response.keyword?.length > 0) {
          const updatedKeywords = response.keyword.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            }
          })
          response.keyword = updatedKeywords
        }
        console.log('response submission', response)
        dispatch(setReviewingSubmission(response))
        dispatch(finishLoading())
      }

      const getSubThemeListAsync = async () => {
        const {
          subThemeList: { data },
        } = sharedState
        setSubTheme(data)
      }

      if (!matchPath) {
        getSubmissionDetailAsync()
        console.log('no match path', matchPath)
      } else {
        dispatch(setReviewingSubmission(defaultValues))
        console.log('has match path', matchPath)
      }
      getSubThemeListAsync()
      getConferenceDetailAsync()
      getCountryListAsync()

      return () => {
        submission
        subThemeList
        conference
      }
    }, [])
  }

  const useUpdate = async (data: any) => {
    console.log('use update', data)
    try {
      dispatch(startLoading())
      const body = buildDataBody(data)
      const response: any = await dispatch(updateSubmission({ ...body, id: Number(id) }))
      if (response.payload.http.status === HTTP_STATUS.SUCCESS) {
        dispatch(setToast({ message: 'Paper successfully updated', variant: 'success' }))
        navigate(ROUTE_PATH.SUBMISSION_LIST)
      } else {
        dispatch(setToast({ message: response.payload.message, variant: 'error' }))
      }
      dispatch(finishLoading())
    } catch (error) {
      console.log('error callback', error)
      dispatch(setToast({ message: 'Error updating paper submission', variant: 'error' }))
    }
  }

  const useCreate = useCallback(async (data: any): Promise<void> => {
    console.log('use create', data)
    try {
      dispatch(startLoading())
      const body = buildDataBody(data)
      const response = await dispatch(createSubmission(body))
      if (response.payload.http.status === HTTP_STATUS.SUCCESS) {
        dispatch(setToast({ message: 'Paper successfully submitted', variant: 'success' }))
        navigate(ROUTE_PATH.SUBMISSION_LIST)
      } else {
        setReviewingSubmission(data)
        if (typeof response.payload.message === 'object' && Object.values(response.payload.message).length > 0) {
          response.payload.message.forEach((message: any) => {
            dispatch(setToast({ message: message, variant: 'error' }))
          })
        } else {
          dispatch(setToast({ message: response.payload.message, variant: 'error' }))
        }
      }
      dispatch(finishLoading())
    } catch (error: any) {
      console.log('error callback', error)
      dispatch(setToast({ message: `Error - ${error?.data?.message}`, variant: 'error' }))
    } finally {
      dispatch(finishLoading())
    }
  }, [])

  const useUnmount = () => {
    useEffect(() => {
      return () => {
        dispatch(submissionSlice.actions.clearForm())
      }
    }, [])
  }

  const useBack = () => {
    navigate(-1) // Navigate back to the previous page
  }

  return {
    useFetchData,
    useBack,
    params,
    matchPath,
    subTheme,
    conference,
    country,
    useCreate,
    useUpdate,
    useUnmount,
  }
}
export const SubmissionDetailHook = {
  useHook,
}
