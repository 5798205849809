import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TSelectRole } from 'src/shared/auth/data/select-role.data'
import { TUser } from 'src/pages/user/data/user.data'
import { getUserDetails, userSlice } from 'src/pages/user/state'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { setUserRole } from 'src/shared/auth/state'
import { HTTP_STATUS } from 'src/shared/http/data'
import { finishLoading, startLoading } from 'src/packages/product/full-page-loader/state'
import { getConferences } from '@/pages/conference/state'

const useHook = () => {
  const dispatch = useAppDispatch()

  const { user } = useAppSelector((state) => state)
  const navigate = useNavigate()

  const useFetchData = () => {
    useEffect(() => {
      let user: any
      const getUserDetailsAsync = async () => {
        dispatch(startLoading())
        try {
          user = await dispatch(getUserDetails())
        } finally {
          dispatch(finishLoading())
        }
      }
      getUserDetailsAsync()
      return () => {
        user
      }
    }, [])
  }

  const useSubmit = async (item: any) => {
    const body: TSelectRole = {
      roleId: item.role.id,
      userId: user.user.id,
      conferenceId: item.conference.id,
    }
    dispatch(startLoading())
    try {
      const response: any = await dispatch(setUserRole(body))
      if (response.payload.status?.toLowerCase() === HTTP_STATUS.SUCCESS) {
        await dispatch(userSlice.actions.setUserRoles(item))
        await dispatch(getConferences({ year: new Date().getFullYear() }))
        navigate('/submission')
      }
    } finally {
      dispatch(finishLoading())
    }
  }

  return { user, useSubmit, useFetchData }
}

const table = ({ submit, data }: { submit: (item: TUser) => void; data: TUser }) => {
  const records = data.role?.map((item) => {
    return { conference: item.conference?.name, role: item }
  })

  const columns = [
    {
      key: 'conference',
      label: 'Conference',
      render: (item: any) => item.conference,
    },
    {
      key: 'role',
      label: 'Roles',
      render: (item: any) => (
        <span onClick={() => submit(item.role)} className="text-blue-500 hover:underline cursor-pointer">
          {item.role.role.name}
        </span>
      ),
    },
  ]

  return { records, columns }
}

const MyRolesHook = {
  useHook,
  table,
}

export default MyRolesHook
